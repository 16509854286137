<div class="hotbar">
	<div class="button" (click)="playClickSound()" [routerLink]="'/home'">Retour</div>
	<div (wheel)="scrolled($event)" class="hotbar-items">
		<div class="hotbar-item-list">
			<ng-container *ngFor="let i of hotbars">
				<div (click)="onclick(items[i-1], i)" [class.current]="currentHotbar===i" class="hotbar-item">
					<img *ngIf="items[i]?.thumbnail" [src]="items[i]?.thumbnail" alt="items[i]?.title" width="32"/>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="vide"></div>
</div>
