import {Component} from '@angular/core';
import {RouterLink} from "@angular/router";
import {AsyncPipe, NgForOf} from "@angular/common";
import {MainComponents} from "../main.components";
import {Background, BackgroundService} from "../../services/background.service";
import {Observable, of} from "rxjs";
import {DataSharingService} from "../../services/data-sharing.service";

@Component({
	selector: 'app-options-background',
	standalone: true,
	imports: [
		AsyncPipe,
		NgForOf,
		RouterLink
	],
	templateUrl: './options-background.component.html',
	styleUrl: './options-background.component.scss'
})
export class OptionsBackgroundComponent extends MainComponents {
	backgrounds?: Observable<Background[]>;
	currentBackground?: Background;

	constructor(private background: BackgroundService, private dataSharing: DataSharingService) {
		super();
		background.background().subscribe((result) => {
			this.backgrounds = of(result);
		});
	}

	updateBackground() {
		this.playClickSound();
		this.dataSharing.setBackground(this.currentBackground!.path);
	}
}
