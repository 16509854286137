import {Component, ViewEncapsulation} from '@angular/core';
import {RouterLink} from '@angular/router';
import {TwitchService} from '../../services/twitch.service';
import {CommonModule} from '@angular/common';
import {environment} from '../../environments/environment';
import {SkinRenderComponent} from "../skin-render/skin-render.component";
import {MainComponents} from "../main.components";

@Component({
	selector: 'app-home',
	standalone: true,
	imports: [RouterLink, CommonModule, SkinRenderComponent],
	templateUrl: './home.component.html',
	styleUrl: './home.component.scss',
	encapsulation: ViewEncapsulation.None
})
export class HomeComponent extends MainComponents {

	twitchInfo: any;
	twitchStreamer = environment.twitchStreamer;
	youtubeStreamer = environment.youtubeStreamer;


	constructor(
		private twitch: TwitchService) {
		super();
		twitch.authentificate().subscribe(token => {
			twitch.channelInfo(token.access_token).subscribe((info: any) => this.twitchInfo = info.data[0]);
		});
	}

	close = () => {
		this.playClickSound();
		window.location.href = "https://www.google.com";
		close();
	};
}
