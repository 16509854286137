import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {YoutubeService} from '../../services/youtube.service';
import {environment} from "../../environments/environment";
import {RouterModule} from "@angular/router";
import {MainComponents} from "../main.components";

@Component({
	selector: 'app-youtube-video',
	standalone: true,
	imports: [CommonModule, RouterModule],
	templateUrl: './youtube-video.component.html',
	styleUrls: ['./youtube-video.component.scss', './youtube-video-mobile.component.scss']
})
export class YoutubeVideoComponent extends MainComponents {

	videos: any[] = [];

	constructor(
		private youtube: YoutubeService) {
		super();
		youtube.latestVideos(environment.youtubeVodChannelId).subscribe((datas: any[]) => this.videos = datas);
	}

	showIframe(videoId: string) {

	}

}
