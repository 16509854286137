<div class="home mobile-no-scroll">
	<div class="logo">
		<img src="assets/img/minecraft_title.png" width="800">
	</div>
	<div class="home-content">
		<div class="left-content">
			<div *ngIf="twitchInfo?.description" [innerHtml]="twitchInfo.description" class="text-block"></div>
		</div>
		<div class="middle-content">
			<div class="minecraft-buttons">
				<div (click)="playClickSound()" [routerLink]="['/solo']" class="minecraft-button">
					<div class="title">Solo</div>
				</div>
				<div (click)="playClickSound()" [routerLink]="['/multi-player']" class="minecraft-button">

					<div class="title">Multi-joueurs <span class="material-symbols-outlined">groups</span></div>
				</div>
				<div (click)="playClickSound()" [routerLink]="['/vod']" class="minecraft-button">
					<div class="title">Stream VOD <span class="material-symbols-outlined">replay</span></div>
				</div>
				<div class="minecraft-sub-buttons">
					<div (click)="playClickSound()" [routerLink]="['/options']" class="minecraft-button">
						<div class="title">Options <span class="material-symbols-outlined">settings</span></div>
					</div>
					<div (click)="close()" class="minecraft-button">
						<div class="title">Quitter le jeu <span class="material-symbols-outlined">exit_to_app</span>
						</div>
					</div>
				</div>
				<div class="minecraft-social-network">
					<a (click)="playClickSound()" [href]="'https://twitch.tv/' + twitchStreamer" class="redirect"
					   target="_blank"><img src="assets/img/twitch.svg"></a>
					<a (click)="playClickSound()" [href]="'https://youtube.com/c/' + youtubeStreamer" class="redirect"
					   target="_blank"><img src="assets/img/youtube-cartoon.png"></a>
					<a (click)="playClickSound()" [href]="'https://instagram.com/' + twitchStreamer" class="redirect"
					   target="_blank"><img src="assets/img/instagram-cartoon.png"></a>
					<a (click)="playClickSound()" [href]="'https://x.com/' + twitchStreamer" class="redirect"
					   target="_blank"><img src="assets/img/twitter-cartoon.png"></a>
					<a (click)="playClickSound()" class="redirect" href="https://discord.com/invite/VzyE8ny3qJ"
					   target="_blank"><img src="assets/img/discord-cartoon.png"></a>
				</div>
			</div>
		</div>

		<div class="right-content">
			<app-skin-render></app-skin-render>
		</div>
	</div>

</div>
