import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Pipe({
	name: 'recipeIconUrl',
	standalone: true
})
export class RecipeIconUrlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {
	}

	transform(value: string): SafeResourceUrl {
		value = value.replace('waxed_', '');
		return `https://golriver.fr/api/datapack/${value.replace('minecraft:', '')}.png`;
	}

}
