import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DataSharingService} from "../../services/data-sharing.service";
import {DataPack, DataPackService} from "../../services/data-pack.service";
import {CommonModule} from "@angular/common";
import {MainComponents} from "../main.components";
import {RecipeIconUrlPipe} from "../../pipes/recipe-icon-url.pipe";
import {ItemTitleFrPipe} from "../../pipes/item-title-fr.pipe";

@Component({
	selector: 'app-server-wiki',
	standalone: true,
	imports: [
		CommonModule,
		RecipeIconUrlPipe,
		ItemTitleFrPipe
	],
	templateUrl: './server-wiki.component.html',
	styleUrls: ['./server-wiki.component.scss', './server-wiki-mobile.component.scss']
})
export class ServerWikiComponent extends MainComponents {

	datas?: DataPack[];
	currentTab: recipe_type = recipe_type.crafting_table;
	recipes?: Map<recipe_type, DataPack[]>;
	stonecutterRecipes?: Map<string, DataPack[]>;
	types = recipe_type;
	currentRecipe?: DataPack;
	craftingRecipe: string[][] = Array(3).fill(null).map(() => Array(3).fill(''));
	currentStoneCutter?: string;
	currentStoneCutterResult?: DataPack;

	constructor(
		private dataSharingService: DataSharingService,
		private activatedRoute: ActivatedRoute,
		private dataPack: DataPackService
	) {
		super()
		this.activatedRoute.data.subscribe(data => {
			this.dataSharingService.setMap(data['isMap']);
		});
		this.recipes = new Map<recipe_type, DataPack[]>();
		this.stonecutterRecipes = new Map<string, DataPack[]>();
		this.recipes.set(recipe_type.crafting_table, [])
			.set(recipe_type.stonecutter, [])
			.set(recipe_type.blast_furnace, []);

		this.dataPack.dataPack().subscribe(datas => {
			this.datas = datas;
			datas.forEach((data: DataPack) => {
				if (data && data.type === 'minecraft:blasting') {
					this.recipes?.get(recipe_type.blast_furnace)?.push(data);
					this.recipes?.get(recipe_type.blast_furnace)?.sort((a, b) => (a.result.id > b.result.id) ? 1 : -1);
				} else if (data && data.type === 'minecraft:stonecutting') {
					this.recipes?.get(recipe_type.stonecutter)?.push(data);
					this.recipes?.get(recipe_type.stonecutter)?.sort((a, b) => (a.result.id > b.result.id) ? 1 : -1);
					if (data.ingredient && data.ingredient.item && !this.stonecutterRecipes?.has(data.ingredient.item)) {
						this.stonecutterRecipes?.set(data.ingredient.item, []);
					}
					if (data.ingredient && data.ingredient.item) {
						this.stonecutterRecipes?.get(data.ingredient.item)?.push(data);
					}
				} else if (data) {
					this.recipes?.get(recipe_type.crafting_table)?.push(data);
					this.recipes?.get(recipe_type.crafting_table)?.sort((a, b) => (a.result.id > b.result.id) ? 1 : -1);
				}
			});
		});
	}

	setCurrentTab(value: recipe_type) {
		this.playClickSound();
		this.currentTab = value;
		this.currentRecipe = undefined;
		this.currentStoneCutter = undefined;
		this.currentStoneCutterResult = undefined;
	}

	setCurrentRecipe(value: DataPack) {
		this.playClickSound();
		this.currentRecipe = value;
		this.craftingRecipe = Array(3).fill(null).map(() => Array(3).fill(''));
		if (this.currentRecipe.type === 'minecraft:crafting_shaped' || this.currentRecipe.type === 'crafting_shaped') {
			value.pattern?.forEach((line: string, index: number) => {
				for (let i = 0; i < line.length; i++) {
					if (line[i].trim()) {
						// @ts-ignore
						this.craftingRecipe[index][i] = value.key[line[i]].item || value.key[line[i]].tag;
					}
				}
			});
		}
		if (this.currentRecipe.type === 'minecraft:crafting_shapeless' || this.currentRecipe.type === 'crafting_shapeless') {
			let i = 0;
			let j = 0;
			value.ingredients?.forEach((ingredient, index) => {
				const i: number = Math.floor(index / 3);
				const j: number = index % 3;
				// @ts-ignore
				this.craftingRecipe[i][j] = ingredient.item;
			});
		}
	}

	getGifVersion(event: Event, value: string) {
		value = value.replace('waxed_', '');
		(event.target as HTMLImageElement).src = `https://golriver.fr/api/datapack/${value.replace('minecraft:', '')}.gif`;
	}

	setCurrenStonecuttertRecipe(recipe: string) {
		this.playClickSound();
		this.currentStoneCutterResult = undefined;
		this.currentStoneCutter = recipe;
	}

	setCurretStonecutterResult(stone: DataPack) {
		this.playClickSound();
		this.currentStoneCutterResult = stone;
	}
}

export enum recipe_type {
	blast_furnace, crafting_table, stonecutter
}
