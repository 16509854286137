import {AfterViewInit, Component} from '@angular/core';
import * as skinview3d from 'skinview3d';
import {CapeService} from "../../services/cape.service";

@Component({
	selector: 'app-skin-render',
	standalone: true,
	imports: [],
	templateUrl: './skin-render.component.html',
	styleUrl: './skin-render.component.scss'
})
export class SkinRenderComponent implements AfterViewInit {

	private capeUrl?: string;

	constructor(private cape: CapeService) {
		this.cape.capeUrl().subscribe((url: string) => {
			this.capeUrl = url;

			const element = document.getElementById('canvas') as HTMLCanvasElement;

			const viewer = new skinview3d.SkinViewer({
				canvas: element,
				width: 300,
				height: 400,
				skin: 'https://mineskin.eu/skin/golriver',
				cape: this.capeUrl,
				animation: new skinview3d.WalkingAnimation(),
				enableControls: false
			});
			viewer.autoRotate = true
			viewer.autoRotateSpeed = -0.5
		});
	}

	ngAfterViewInit(): void {

	}
}