import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class ShaderService {

	private apiUrl = 'https://golriver.fr/api/shader';

	constructor(
		private http: HttpClient,
	) {
	}

	shaders = (): Observable<Shader[]> =>
		this.http
			.get<Shader[]>(`${this.apiUrl}/shader.json`)
			.pipe(
				map((response: Shader[]) =>
					response.map((shader: Shader) => {
						shader.url = `${this.apiUrl}/${shader.url}`;
						return shader;
					})
				)
			);
}

export interface Shader {
	name: string;
	url: string;
}