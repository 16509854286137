<div class="ressouce-pack">
	<div class="contenu"> <!--PARTIE SUPERIEURE-->
		<div class="left-content"> <!--// PARTIE DROITE + SCROLL-->
			<div class="version-container"> <!--// PARTIE DROITE-->
				<div (click)="playClickSound()"  class="versions">
					<ng-container *ngIf="packs">
						<div class="version" *ngFor="let key of packKeys" (click)="currentVideos=packs[key]"
							 [class.current]="currentVideos===packs[key]">
							<div class="version-icon arrow">
								<img alt="Icone" [src]="packs[key].icon">
							</div>
							<div class="version-info">
								<div class="title" [innerHTML]="packs[key].name"></div>
								<div class="description" [innerHTML]="packs[key].description">
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="right-content">
			<div class="version-container">
				<div class="versions" *ngIf="currentVideos">
					<a (click)="playClickSound()"  *ngFor="let version of versionKeys" [href]="currentVideos.version[version].url" download>
						<div class="version">
							<div class="version-icon arrow downloadable">
								<img alt="Icone" [src]="currentVideos.icon"/>
								<div class="version-value" [textContent]="version"></div>
							</div>
							<div (click)="playClickSound()" class="version-infos">
								Compatible avec :
								<div class="version-compatibility">
									<div class="minecraft-version"
										 *ngFor="let minecraftVersion of currentVideos.version[version].compatibleWith"
										 [textContent]="minecraftVersion"></div>
								</div>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="minecraft-buttons">
		<div (click)="playClickSound()" [routerLink]="['/options']" class="minecraft-button quitter">
			<div class="title">Terminé</div>
		</div>
	</div>
</div>