<div class="server-wiki">
	<div class="server-wiki-recipes">
		<div class="server-wiki-navs">
			<div class="server-wiki-nav" [class.current]="currentTab===types.crafting_table"
				 (click)="setCurrentTab(types.crafting_table)">
				<img src="https://golriver.fr/api/datapack/crafting_table.png" alt="crafting_table">
			</div>
			<div class="server-wiki-nav" [class.current]="currentTab===types.stonecutter"
				 (click)="setCurrentTab(types.stonecutter)">
				<img src="https://golriver.fr/api/datapack/stonecutter.gif" alt="stonecutter"/>
			</div>
			<div class="server-wiki-nav" [class.current]="currentTab===types.blast_furnace"
				 (click)="setCurrentTab(types.blast_furnace)">
				<img src="https://golriver.fr/api/datapack/blast_furnace.png" alt="blast_furnace"/>
			</div>
		</div>
		<div class="server-wiki-recipes-list">
			<ng-container *ngIf="recipes && currentTab!==types.stonecutter; else stoneCutterList">
				<div class="server-wiki-recipe">
					<div class="server-wiki-items">
						<div class="server-wiki-item" *ngFor="let recipe of recipes.get(currentTab)"
							 [title]="recipe.result?.id|itemTitleFr" (click)="setCurrentRecipe(recipe)">
							<img *ngIf="recipe.result" [src]="recipe.result.id|recipeIconUrl"
								 [attr.alt]="recipe.result.id|itemTitleFr" class="server-wiki-item-icon"
								 (error)="getGifVersion($event,recipe.result.id)"/>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-template #stoneCutterList>
				<div class="server-wiki-recipe">
					<div class="server-wiki-items">
						<div class="server-wiki-item" *ngFor="let recipe of stonecutterRecipes?.keys()"
							 [title]="recipe|itemTitleFr" (click)="setCurrenStonecuttertRecipe(recipe)">
							<img *ngIf="recipe" [src]="recipe|recipeIconUrl"
								 [attr.alt]="recipe|itemTitleFr" class="server-wiki-item-icon"
								 (error)="getGifVersion($event,recipe)"/>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
	<div class="server-wiki-ingredients">
		<ng-container *ngIf="currentTab===types.crafting_table; else stoneCutter">
			<div class="recipe-crafting-table">
				<div class="crafting-table-title">Fabrication</div>
				<div class="crafting-table">
					<div class="crafting-table-recipe">
						<div class="crafting-table-line" *ngFor="let line of craftingRecipe">
							<div class="crafting-table-cell" *ngFor="let cell of line">
								<img *ngIf="cell && cell.trim()" [src]="cell|recipeIconUrl"
									 [attr.alt]="cell|itemTitleFr"
									 class="crafting-cell-item" (error)="getGifVersion($event,cell)">
							</div>
						</div>
					</div>
					<div class="crafting-table-arrow"></div>
					<div class="crafting-table-result">
						<img *ngIf="currentRecipe && currentRecipe.result && currentRecipe.result.id"
							 [src]="currentRecipe.result.id|recipeIconUrl"
							 [attr.alt]="currentRecipe.result.id|itemTitleFr" class="crafting-cell-item"
							 (error)="getGifVersion($event,currentRecipe.result.id)">
						<div class="crafting-table-result-count"
							 *ngIf="currentRecipe && currentRecipe.result && currentRecipe.result.count && currentRecipe.result.count>1"
							 [textContent]="currentRecipe.result.count"></div>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-template #stoneCutter>
			<ng-container *ngIf="currentTab===types.stonecutter; else blastFurnace">
				<div class="recipe-stonecutter">
					<div class="stonecutter-title">Tailleur de pierre</div>
					<div class="stonecutter">
						<div class="stonecutter-source">
							<img *ngIf="currentStoneCutter"
								 [src]="currentStoneCutter|recipeIconUrl"
								 [attr.alt]="currentStoneCutter|itemTitleFr"
								 class="stonecutter-source-item"
								 (error)="getGifVersion($event,currentStoneCutter)">
						</div>
						<div class="stonecutter-proposal">
							<ng-container *ngIf="currentStoneCutter">
								<div class="stonecutter-proposal-items">
									<div class="stonecutter-proposal-item"
										 *ngFor="let stone of stonecutterRecipes?.get(currentStoneCutter)"
										 (click)="setCurretStonecutterResult(stone)"
										 [class.selected]="currentStoneCutterResult===stone">
										<img *ngIf="stone && stone.result && stone.result.id"
											 [src]="stone.result.id|recipeIconUrl"
											 [attr.alt]="stone.result.id|itemTitleFr"
											 class="stonecutter-proposal-item-icon"
											 (error)="getGifVersion($event,stone.result.id)">
									</div>
								</div>
							</ng-container>
						</div>
						<div class="stonecutter-result">
							<img *ngIf="currentStoneCutterResult && currentStoneCutterResult.result && currentStoneCutterResult.result.id"
								 [src]="currentStoneCutterResult.result.id|recipeIconUrl"
								 [attr.alt]="currentStoneCutterResult.result.id|itemTitleFr"
								 class="stonecutter-result-item"
								 (error)="getGifVersion($event,currentStoneCutterResult.result.id)">
							<div class="stonecutter-result-count"
								 *ngIf="currentStoneCutterResult && currentStoneCutterResult.result && currentStoneCutterResult.result.count && currentStoneCutterResult.result.count>1"
								 [textContent]="currentStoneCutterResult.result.count"></div>
						</div>
					</div>
				</div>
			</ng-container>
		</ng-template>
		<ng-template #blastFurnace>
			<div class="recipe-blast-furnace">
				<div class="blast-furnace-title">Haut fourneau</div>
				<div class="blast-furnace">
					<div class="blast-furnace-recipe">
						<div class="blast-furnace-recipe-item">
							<img *ngIf="currentRecipe && currentRecipe.ingredient && currentRecipe.ingredient.item"
								 [src]="currentRecipe.ingredient.item|recipeIconUrl"
								 [attr.alt]="currentRecipe.ingredient.item|itemTitleFr"
								 class="blast-furnace-recipe-item-image">
						</div>
						<img *ngIf="currentRecipe" src="assets/img/fire.png" class="blast-furnace-recipe-flame">
						<img *ngIf="!currentRecipe" src="assets/img/flame.png" class="blast-furnace-recipe-flame">
						<div class="blast-furnace-recipe-combustible">
							<img *ngIf="currentRecipe" src="assets/img/lava-bucket.png"
								 class="blast-furnace-recipe-flame">
						</div>
					</div>
					<div class="blast-furnace-arrow"></div>
					<div class="blast-furnace-result">
						<img *ngIf="currentRecipe && currentRecipe.result && currentRecipe.result.id"
							 [src]="currentRecipe.result.id|recipeIconUrl"
							 [attr.alt]="currentRecipe.result.id|itemTitleFr" class="blast-furnace-result-item"
							 (error)="getGifVersion($event,currentRecipe.result.id)">
						<div class="blast-furnace-result-count"
							 *ngIf="currentRecipe && currentRecipe.result && currentRecipe.result.count && currentRecipe.result.count>1"
							 [textContent]="currentRecipe.result.count"></div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>