<div class=multi-players>
	<div class="header">
		<div class="top">
			<img src="assets/img/chomagerie_title.png"/>
			<div class="bottom no-display-mobile">
				<div class="text">Actuellement en ligne :</div>
				<div class="players">
					<ng-container *ngIf="players && players.players; else noPlayer">
						<img *ngFor="let player of players.players" [src]="player.icon" [alt]="player.name"
							 [title]="player.name"/>
					</ng-container>
					<ng-template #noPlayer>Aucun joueurs</ng-template>
				</div>
			</div>
			<div *ngIf="players" class="players-live-counter no-display-mobile">
				{{ players.count }}/{{ players.limit }}
			</div>
			<img src="assets/img/chomagerie.png" alt="logo chomagerie" class="no-display-mobile"/>
		</div>

	</div>
	<div class="content">
		<div class="lateral-bar">
			<div class="menu">
				<div (click)="playClickSound()" class="menu-item" [routerLink]="['new-instance']"
					 routerLinkActive="selected">Créer une instance
				</div>
				<div (click)="playClickSound()" class="menu-item" [routerLink]="['connection']"
					 routerLinkActive="selected">Comment se connecter
					?
				</div>
				<div (click)="playClickSound()" class="menu-item" [routerLink]="['rules']" routerLinkActive="selected">
					Règles
				</div>
				<div (click)="playClickSound()" class="menu-item" [routerLink]="['wiki']" routerLinkActive="selected">
					Crafts
				</div>
				<div (click)="playClickSound()" class="menu-item no-display-mobile" [routerLink]="['map']"
					 routerLinkActive="selected">Map
				</div>
				<div (click)="playClickSound()" class="menu-item" [routerLink]="['tools']" routerLinkActive="selected">
					Outils
				</div>
			</div>
			<div class="button" (click)="playClickSound()" [routerLink]="'/home'">Retour</div>
		</div>
		<div class="details">
			<div class="details-content"
				 [class.map]="(isMap|async)==='map'" [class.no-background]="(isMap|async)==='no-back'">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>
