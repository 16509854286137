import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { combineLatest, Subject, debounceTime } from 'rxjs';
import { CommonModule } from '@angular/common';
import { DiscordService } from '../../services/discord.service';
import { TwitchService } from '../../services/twitch.service';
import { YoutubeService, YoutubeVideos } from '../../services/youtube.service';
import { HotbarComponent } from '../hotbar/hotbar.component';
import { environment } from "../../environments/environment";

@Component({
	selector: 'app-solo',
	standalone: true,
	templateUrl: './solo.component.html',
	styleUrls: ['./solo.component.scss', './solo-mobile.component.scss'],
	encapsulation: ViewEncapsulation.None,
	imports: [CommonModule, HotbarComponent]
})
export class SoloComponent {

	statData: { [key: string]: StatItem } = {
		discord: { icon: 'assets/img/discord.svg', count: 0, status: false, memberOnline: 0 },
		twitch: { icon: 'assets/img/twitch.svg', count: 0, status: false, memberOnline: 0 },
		youtube: { icon: 'assets/img/youtube.svg', count: 0, status: false, memberOnline: 0 }
	};

	videos: YoutubeVideos[] = [];
	currentId: number = 0;
	videoSubjectChange: Subject<any> = new Subject();

	@ViewChild("customScollbar") customScollbar!: ElementRef;

	constructor(
		private discord: DiscordService,
		private twitch: TwitchService,
		private youtube: YoutubeService
	) {
		// Fetch Twitch data
		twitch.authentificate().subscribe(token => {
			combineLatest(twitch.followersCount(token.access_token), twitch.streamStatus(token.access_token))
				.subscribe(([twitchCount, twitchStreamStatus]) => {
					this.statData['twitch'].count = twitchCount.total;
					this.statData['twitch'].status = twitchStreamStatus.data[0]?.type === 'live';
				});
		});

		combineLatest(
			youtube.subscriberCount(), youtube.latestVideos(environment.youtubeChannelId), discord.getDiscordData())
			.subscribe(([youtubeStat, youtubeVideos, discordStats]) => {
				this.videos = youtubeVideos;
				this.statData['youtube'].count = parseInt(youtubeStat.items[0].statistics.subscriberCount, 10);
				this.statData['discord'].count = discordStats.memberCount;
				this.statData['discord'].memberOnline = discordStats.presenceCount;
				this.currentId = 0;
				this.arroundDescription();
			});
		this.videoSubjectChange.pipe(debounceTime(200)).subscribe(event => this.updateCurrentVideo(event));
	}

	videoChanged = (event: any) => {
		this.videoSubjectChange.next(event);
	}

	updateCurrentVideo = (event: any) => {
		this.currentId = event.index;
		this.arroundDescription();
	}

	arroundDescription = () => {
		setTimeout(() => {
			if (this.customScollbar.nativeElement.scrollHeight > this.customScollbar.nativeElement.clientHeight) {
				this.customScollbar.nativeElement.classList.remove("without-scroll")
			} else {
				this.customScollbar.nativeElement.classList.add("without-scroll")
			}
		}, 1)
	}
}

export interface StatItem {
	icon: string;
	count: number;
	status: boolean;
	memberOnline: number;
}
