import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RouterLink} from '@angular/router';
import {Subject} from "rxjs";
import {MainComponents} from "../main.components";

@Component({
	selector: 'app-hotbar',
	standalone: true,
	imports: [CommonModule, RouterLink],
	templateUrl: './hotbar.component.html',
	styleUrl: './hotbar.component.scss'
})
export class HotbarComponent extends MainComponents {

	@Input() items: any[] = [];

	hotbars = [0, 1, 2, 3, 4, 5, 6, 7, 8];
	@Input() currentHotbar = 0;

	@Output() changed: EventEmitter<any> = new EventEmitter<any>();

	private debouncer: Subject<any> = new Subject();

	constructor() {
		super();
	}

	scrolled = (event: WheelEvent) => {
		if (event.deltaY > 0 && this.currentHotbar < (this.hotbars.length - 1)) {
			this.currentHotbar++;
		} else if (event.deltaY > 0 && this.currentHotbar === (this.hotbars.length - 1)) {
			this.currentHotbar = 0;
		} else if (event.deltaY < 0 && this.currentHotbar > 0) {
			this.currentHotbar--;
		} else if (event.deltaY < 0 && this.currentHotbar === 0) {
			this.currentHotbar = this.hotbars.length - 1;
		}
		this.changed.emit({event: this.items[this.currentHotbar], index: this.currentHotbar});

	};

	onclick = (event: any, index: number) => {
		this.currentHotbar = index;
		this.changed.emit({index, event});
	}

}
