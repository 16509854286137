import {Routes} from '@angular/router';
import {HomeComponent} from '../components/home/home.component';
import {SoloComponent} from '../components/solo/solo.component';
import {YoutubeVideoComponent} from '../components/youtube-video/youtube-video.component';
import {MultiPlayersComponent} from '../components/multijoueurs/multi-players.component';
import {OptionsComponent} from "../components/options/options.component";
import {RessourcePackComponent} from "../components/ressource-pack/ressource-pack.component";
import {ShadersComponent} from "../components/shaders/shaders.component";
import {OptionsBackgroundComponent} from "../components/options-background/options-background.component";
import {ServerWikiComponent} from "../components/server-wiki/server-wiki.component";
import {MarkdownContentComponent} from "../components/markdown-content/markdown-content.component";
import {MapComponent} from "../components/map/map.component";

export const routes: Routes = [
	{path: 'home', component: HomeComponent},
	{path: 'solo', component: SoloComponent},
	{path: 'multi-player', component: MultiPlayersComponent, children: [
			{path:'new-instance', component: MarkdownContentComponent, data: {title: "how-to.png", content:"minecraft-instance.md", isMap: "black"}},
			{path:'connection', component: MarkdownContentComponent, data: {title: "connection.png", content:"server-connection.md", isMap: "black"}},
			{path:'rules', component: MarkdownContentComponent, data: {title: "rules.png", content:"server-rules.md", isMap: "black"}},
			{path:'wiki', component: ServerWikiComponent, data: {isMap: "no-back"}},
			{path:'tools', component: MarkdownContentComponent, data: {title: "tools.png", content:"server-tools.md", isMap: "black"}},
			{path:'map', component: MapComponent, data: {isMap: "map"}},
			{path: '**', pathMatch: 'full', redirectTo: 'wiki'}
		]},
	{path: 'vod', component: YoutubeVideoComponent},
	{path: 'options', component: OptionsComponent},
	{path: 'resource-pack', component: RessourcePackComponent},
	{path: 'shaders', component: ShadersComponent},
	{path: 'options-background', component: OptionsBackgroundComponent},
	{path: '**', pathMatch: 'full', redirectTo: '/home'}
];
