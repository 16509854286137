<div class="markdown-content">
	<div class="section-icon">
		<img [src]="titleIcon"/>
	</div>
	<div class="section-content">
		<markdown
				lineHighlight
				[line]="'6, 10-16'"
				[lineOffset]="5"
				[src]="link"
		>
		</markdown>
	</div>
</div>
