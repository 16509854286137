<div class="options">
	<div class="header">
		<div class="titre">
			Arrière plan
		</div>
		<div class="legende">
			Selectionnez
		</div>
	</div>
	<div class="options">
		<ng-container>
			<div class="options-list">
				<div class="option" *ngFor="let bg of (backgrounds|async)" (click)="currentBackground=bg"
					 [class.current]="currentBackground===bg">
					<div class="option-info">
						<div class="title" [innerHTML]="bg.name"></div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="buttons">
		<div (click)="playClickSound()" [routerLink]="['/options']" class="button">Retour</div>
		<div (click)="updateBackground()" class="button">Séléctionner</div>
	</div>
</div>
