import {Component} from '@angular/core';
import {DataSharingService} from "../../services/data-sharing.service";
import {ActivatedRoute} from "@angular/router";

@Component({
	selector: 'app-map',
	standalone: true,
	imports: [],
	templateUrl: './map.component.html',
	styleUrl: './map.component.scss'
})
export class MapComponent {

	constructor(private dataSharingService: DataSharingService, private activatedRoute: ActivatedRoute) {
		this.activatedRoute.data.subscribe(data => {
			this.dataSharingService.setMap(data['isMap']);
		});
	}

}
