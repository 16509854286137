import {Component, ViewEncapsulation} from '@angular/core';
import {MarkdownModule} from "ngx-markdown";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DataSharingService} from "../../services/data-sharing.service";

@Component({
	selector: 'app-markdown-content',
	standalone: true,
	imports: [
		MarkdownModule
	],
	templateUrl: './markdown-content.component.html',
	styleUrls: ['./markdown-content.component.scss', 'markdown-content-mobile.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class MarkdownContentComponent {

	titleIcon?: SafeResourceUrl;
	link?: string;
	api = 'https://golriver.fr/api/markdown/';

	constructor(private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer, private dataSharingService: DataSharingService) {
		activatedRoute.data.subscribe((data: any) => {
			this.dataSharingService.setMap(data['isMap']);
			this.link = `${this.api}${data.content}`;
			this.titleIcon = sanitizer.bypassSecurityTrustResourceUrl(`assets/img/${data.title}`)
		});
	}
}
