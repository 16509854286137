import {AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ServerStat, ServeurSubService} from "../../services/serveur-sub.service";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {MainComponents} from "../main.components";
import {DataSharingService} from "../../services/data-sharing.service";
import {Observable, of, Subscription} from "rxjs";

@Component({
	selector: 'app-multi-players',
	standalone: true,
	imports: [CommonModule, RouterModule],
	templateUrl: './multi-players.component.html',
	styleUrls: ['./multi-players.component.scss', './multi-players-mobile.component.scss']
})
export class MultiPlayersComponent extends MainComponents implements AfterContentInit, OnDestroy {

	players?: ServerStat;
	isMap: Observable<string> = of('black');
	dataObserver?: Subscription;

	constructor(private serverSubService: ServeurSubService, private dataSharing: DataSharingService) {
		super();
		this.serverSubService.serverStat().subscribe((data: ServerStat) => {
			this.players = data;
		});
	}

	ngAfterContentInit() {
		this.dataObserver = this.dataSharing.getMap().subscribe((map: string) => {
			this.isMap = of(map);
		});
	}

	ngOnDestroy() {
		this.dataObserver?.unsubscribe();
	}
}
