<div class="youtube-videos">
	<div class="videos mobile-no-scroll">
		<div class="videos-container">
			<div *ngFor="let video of videos" class="video">
				<ng-container *ngIf="!video.display; else displayvideo">
					<div class="video-title" [textContent]="video.title" (click)="video.display=true"></div>
					<img [src]="video.thumbnail" (click)="video.display=true">
				</ng-container>
				<ng-template #displayvideo>
				<iframe [src]="video.embeddedUrl"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen height="315"
						referrerpolicy="strict-origin-when-cross-origin"
						title="YouTube video player" width="560"></iframe>
				</ng-template>
			</div>

			<div class="voir-plus">Pour voir plus de vidéo, <a href="https://www.youtube.com/@GOLRIVER-Vod"
															   target="_blank">voir directement sur la chaine <img
					height="24" src="assets/img/youtube.svg"> VOD</a></div>
		</div>
	</div>
	<div class="bottom">
		<div class="button" (click)="playClickSound()" [routerLink]="'/home'">Retour</div>
		<div class="button" (click)="playClickSound()">
			<a class="lien-chaine" href="https://www.youtube.com/@GOLRIVER-Vod" target="_blank">Accéder à la chaine <img
					height="24" src="assets/img/youtube.svg"> VOD</a>
		</div>
	</div>
</div>