import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class ServeurSubService {

	private api = 'https://api.mcsrvstat.us/3/chomagerie.golriver.fr';

	constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {

	}

	serverStat = (): Observable<ServerStat> => {
		return this.http.get(this.api)
			.pipe(map((data: any) => {
				const playerList = data['players']['list']?.map((player: any) => {
					return {
						name: player.name,
						icon: this.domSanitizer.bypassSecurityTrustResourceUrl(`https://minotar.net/helm/${player.name}`)
					};
				});
				return {
					limit: 0 || data['players']['max'],
					count: playerList ? playerList.length:0,
					players: playerList || []
				} as ServerStat;
			}));
	}
}

export interface ServerStat {
	limit: number;
	count: number;
	players: {
		name: string;
		icon: SafeResourceUrl;
	}[],
}
