import {Pipe, PipeTransform} from '@angular/core';
import {TRANSLATE} from "../assets/json/translate";

@Pipe({
	name: 'itemTitleFr',
	standalone: true
})
export class ItemTitleFrPipe implements PipeTransform {

	transform(value?: string): string|undefined {
		return value && TRANSLATE[value] ? TRANSLATE[value] : value;
	}

}
