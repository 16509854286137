<div class="shaders">
	<div class="header">
		<div class="titre">
			Shader Packs
		</div>
		<div class="legende">
			Selectionnez
		</div>
	</div>
	<div class="shaders-container">
		<ng-container>
			<div class="shaders-list">
				<div class="shader" *ngFor="let shader of (shaders|async)" (click)="currentShader=shader"
					 [class.current]="currentShader===shader">
					<div (click)="playClickSound()" class="shader-info">
						<div class="title" [innerHTML]="shader.name"></div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="buttons">
		<div (click)="playClickSound()" [routerLink]="['/options']" class="button">Retour</div>
		<div (click)="telecharger()" class="button">Télécharger</div>
	</div>
</div>
