import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject,
	OnDestroy,
	OnInit,
	ViewEncapsulation
} from '@angular/core';
import {NavigationEnd, Router, RouterModule, RouterOutlet,} from '@angular/router';
import {CubemapProjection, View360Options} from '@egjs/view360';
import {NgxView360Module} from '@egjs/ngx-view360';
import {filter, Observable, of, Subscription} from "rxjs";
import {BackgroundService} from "../services/background.service";
import {AsyncPipe, CommonModule} from "@angular/common";
import {DataSharingService} from "../services/data-sharing.service";
import {MatButtonModule} from "@angular/material/button";
import {MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {CreditsComponent} from "../credits/credits.component";


@Component({
	selector: 'app-root',
	standalone: true,
	imports: [
		RouterOutlet,
		RouterModule,
		NgxView360Module,
		AsyncPipe,
		CommonModule,
		MatButtonModule,
		MatDialogTitle,
		MatDialogContent,
		MatDialogActions,
		MatDialogClose],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit, OnDestroy {
	private current = new Date();
	// @ts-ignore
	private observed: Subscription;
	year = this.current.getFullYear();
	noBlur = false;
	defaultOptions: Partial<View360Options> = {
		autoplay: {
			delay: 0,
			delayOnMouseLeave: 0,
			speed: .2,
			pauseOnHover: false,
		},
		rotate: {
			disableKeyboard: true,
			disablePitch: true,
			disableYaw: true
		},
		disableContextMenu: false,
		scrollable: false,
		fov: 110,
		initialZoom: .8,
		projection: new CubemapProjection({
			cubemapOrder: "FRBLUD",
			src: []
		}),
	};
	options ?: Observable<Partial<View360Options>>;
	readonly dialog = inject(MatDialog)

	constructor(private router: Router, private background: BackgroundService, private cdr: ChangeDetectorRef, private dataSharing: DataSharingService) {
		dataSharing.getBackground().subscribe(background => {
			this.options = of({
				...this.defaultOptions,
				projection: new CubemapProjection({
					cubemapOrder: "FRBLUD",
					src: [
						`${background}panorama_0.png`,
						`${background}panorama_1.png`,
						`${background}panorama_2.png`,
						`${background}panorama_3.png`,
						`${background}panorama_4.png`,
						`${background}panorama_5.png`,
					]
				}),
			});
			this.cdr.detectChanges();
		});
	}

	ngOnInit() {
		this.observed = this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(
				(event) => (this.noBlur = event.urlAfterRedirects === '/home')
			);
		const bg = localStorage.getItem('background');
		if (bg) {
			this.dataSharing.setBackground(bg);
		} else {
			this.background.backgroundDefault().subscribe(result => {
				if (result) {
					this.dataSharing.setBackground(result);
				}
			}, error => {
				console.error(error);
			});
		}
	}

	ngOnDestroy() {
		if (this.observed) {
			this.observed.unsubscribe();
		}
	}

	credits() {
		const dialogRef = this.dialog.open(CreditsComponent, {width:"60svw", height:"fit-content", minWidth:"400px"});
	}
}
