<ng-container *ngIf="(options|async) as opts">
	<ngx-view360 [options]="opts" [class.no-blur]="noBlur"/>
</ng-container>
<div class="main-page">
	<div class="main-content">
		<router-outlet></router-outlet>
	</div>
	<div class="footer">
		<div class="minecraft-version">Version 1.21</div>
		<div class="site-info">
			<span class="credits" (click)="credits()">Crédits</span>
			<span class="copyright"> &copy; GOLRIVER {{ year }}</span>
		</div>
	</div>
</div>